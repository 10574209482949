import { createRouter, createWebHistory } from "@ionic/vue-router";
//import { RouteRecordRaw } from 'vue-router';
import Tabs from "../views/Tabs.vue";
import { authStateObserver } from "@/services/firebase.js";

const routes = [
	{
		path: "/",
		redirect: "/dashboard",
	},
	{
		path: "/tabs",
		component: Tabs,
		meta: { requiresAuth: true },
		children: [
			{
				path: "",
				redirect: "/dashboard",
			},
			{
				path: "dashboard",
				component: () => import("@/views/main/Dashboard.vue"),
				alias: "/dashboard",
			},
			{
				path: "backfill",
				component: () => import("@/views/main/Backfill.vue"),
				alias: "/backfill",
			},
		],
	},
	{
		path: "/licenses",
		redirect: "/licenses.html",
	},
	{
		path: "/signin",
		redirect: "/signin/email",
	},
	{
		path: "/signout",
		component: () => import("@/views/Auth/SignOut.vue"),
	},
	{
		path: "/signin/email",
		component: () => import("@/views/Auth/EmailSignIn.vue"),
	},
	{
		path: "/signin/email/resetpassword",
		component: () => import("@/views/Auth/PasswordReset.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeResolve(async (to, from, next) => {
	try {
		console.log("current auth user");
		let currentAuthUser = await authStateObserver();
		console.log(currentAuthUser);
		/*console.log('if true');
    console.log(currentAuthUser == true)*/
		if (currentAuthUser == null) {
			if (to.matched.some((record) => record.meta.requiresAuth)) {
				next({
					path: "/signin",
				});
			} else {
				next();
			}
		} else {
			const tokenResult = await currentAuthUser.getIdTokenResult();
			console.log("claims");
			console.log(tokenResult.claims);

			if (tokenResult.claims.admin) {
				next();
			} else {
				next({ path: "/signin" });
			}
		}
	} catch (err) {
		console.log(err);
		next();
	}
});

export default router;

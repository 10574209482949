<template>
	<ion-page>
		<ion-tabs>
			<ion-router-outlet></ion-router-outlet>
			<ion-tab-bar slot="top">
				<ion-tab-button tab="backfill" href="/backfill">
					<ion-icon :icon="arrowUndo" />
					<ion-label>Garmin Backfill</ion-label>
				</ion-tab-button>
				<ion-tab-button tab="dashboard" href="/dashboard">
					<ion-icon src="/assets/Runxact_Logo_X 1 (Traced).svg" />
					<ion-label>Dashboard</ion-label>
				</ion-tab-button>
			</ion-tab-bar>
		</ion-tabs>
	</ion-page>
</template>

<script>
	import {
		IonTabBar,
		IonTabButton,
		IonTabs,
		IonLabel,
		IonIcon,
		IonPage,
		IonRouterOutlet,
		//IonBadge,
	} from "@ionic/vue";
	import {
		create,
		home,
		statsChart,
		trendingUp,
		stopwatch,
		list,
		arrowUndo,
	} from "ionicons/icons";

	export default {
		// eslint-disable-next-line vue/multi-word-component-names
		name: "Tabs",
		components: {
			IonLabel,
			IonTabs,
			IonTabBar,
			IonTabButton,
			IonIcon,
			IonPage,
			IonRouterOutlet,
			//IonBadge,
		},
		setup() {
			return {
				create,
				home,
				statsChart,
				trendingUp,
				stopwatch,
				list,
				arrowUndo,
			};
		},
	};
</script>

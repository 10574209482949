<template>
	<Ion-page>
		<ion-header style="height: 60px">
			<ion-toolbar>
				<slot name="toolbar" />
				<img
					height="50"
					src="assets/Runxact_Logo_Logotype.svg"
					class="center"
				/>
				<ion-icon
					:icon="settings"
					class="settings"
					@click="gotoSettings"
					v-show="showSettings"
				/>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<ion-refresher
				slot="fixed"
				@ionRefresh="refresh($event)"
				color="secondary"
			>
				<ion-refresher-content></ion-refresher-content>
			</ion-refresher>
			<div class="centeredView">
				<slot style="padding: 10px" />
			</div>
		</ion-content>
	</Ion-page>
</template>

<script>
	import {
		IonPage,
		IonHeader,
		IonContent,
		IonToolbar,
		IonRefresherContent,
		IonRefresher,
		IonIcon,
	} from "@ionic/vue";

	import { settings } from "ionicons/icons";

	export default {
		components: {
			IonPage,
			IonHeader,
			IonToolbar,

			IonRefresherContent,
			IonRefresher,
			IonContent,
			IonIcon,
		},
		setup() {
			return {
				settings,
			};
		},

		methods: {
			refresh(event) {
				this.$emit("refresh", event);
			},
			gotoSettings() {
				this.$router.push("/dashboard/settings");
			},
		},
		computed: {
			showSettings() {
				if (this.$route.path.endsWith("settings")) return false;
				else return true;
			},
		},
	};
</script>

<style scoped>
	.center {
		margin: 0 auto;
		justify-content: center;
		display: block;
	}
	.ion-back-button {
		color: var(--ion-color-primary);
	}
	.fixed {
		position: absolute;
		width: 200px;
		height: fit-content;
		padding-bottom: 20px;
		left: calc(50% - 100px);
	}
	.title {
		position: absolute;
		width: 100%;
		height: fit-content;
		left: 0px;
		top: 0px;
	}
	.centeredView {
		max-width: 1000px;
		margin: auto;
		height: fit-content;
		color: var(--ion-color-step-150);
	}
	.settings {
		color: black;
		position: absolute;
		right: 10px;
		top: 17.5px;
		font-size: 25px;
	}
</style>

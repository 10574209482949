<template>
	<ion-app>
		<ion-router-outlet />
	</ion-app>
</template>

<script>
	import { IonApp, IonRouterOutlet } from "@ionic/vue";

	import { setEndpointURL } from "@/services/api.js";

	export default {
		name: "App",
		components: {
			IonApp,
			IonRouterOutlet,
		},
		data() {
			return {
				signedIn: false,
			};
		},
		mounted() {
			console.log(window.location.hostname);
			if (window.location.hostname == "localhost") {
				setEndpointURL("http://" + window.location.hostname + ":4962/api/v1");
			}
		},
	};
</script>

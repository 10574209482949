/* eslint-disable no-async-promise-executor */
import axios from "axios";
import moment from "moment";
//import { store } from "../store/index.js";

import { getIdToken, getCurrentAuthUser } from "./firebase";

let secureEndpointURL =
	"https://1zdyrb4nv0.execute-api.eu-west-2.amazonaws.com/api/v1";

const setEndpointURL = (location) => {
	secureEndpointURL = location;
	//console.log(location);
};

/*const getUid = () => {
	if (store.state.overrideUid) return store.state.overrideUid;
	else return getCurrentAuthUser().uid;
};*/

const getEmail = () => getCurrentAuthUser().email;

const convertToTimeString = (input, userString = false) => {
	let time = moment.duration(input * 1000);
	let hours = time.hours();
	let minutes = time.minutes();
	let seconds = time.seconds();

	if (hours < 10) {
		hours = "0" + hours;
	}

	if (minutes < 10) {
		minutes = "0" + minutes;
	}

	if (seconds < 10) {
		seconds = "0" + seconds;
	}

	let output;

	if (userString) {
		if (hours > 0) {
			output = hours + "h " + minutes + "m " + seconds + "s";
		} else {
			output = minutes + "m " + seconds + "s ";
		}
	} else {
		if (hours > 0) {
			output = hours + ":" + minutes + ":" + seconds;
		} else {
			output = minutes + ":" + seconds;
		}
	}
	return output;
};

const garminBackfill = async (start, end, mode, uidToBackfill = null) => {
	let idToken = await getIdToken();

	let params;

	if (mode == "all") {
		params = {
			start,
			end,
			mode,
		};
	} else {
		params = {
			start,
			end,
			mode,
			uid: uidToBackfill,
		};
	}

	if (idToken) {
		try {
			let res = await axios.get(secureEndpointURL + "/garmin/backfill", {
				params,
				headers: {
					Authorization: idToken,
					"Content-Type": "application/x-www-form-urlencoded",
				},
			});

			console.log(JSON.stringify(res.data));

			return JSON.stringify(res.data);
		} catch (e) {
			console.log(e.response);
			if (e.response.status == 400) {
				return "userNeedsSignUp";
			} else {
				throw new Error(e);
			}
		}
	}
};

export { convertToTimeString, setEndpointURL, getEmail, garminBackfill };

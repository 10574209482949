const firebaseConfig = {
	apiKey: "AIzaSyAOKe6iGG_n8QSUwjmprKJD7k_-Z5r7FHA",
	authDomain: "macro-storm-314519.firebaseapp.com",
	projectId: "macro-storm-314519",
	storageBucket: "macro-storm-314519.appspot.com",
	messagingSenderId: "819200473415",
	appId: "1:819200473415:web:ddabcaa07ea46db5a7b462",
	measurementId: "G-DCTGY43ZWM",
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();

import firebase from "firebase";

//import { store } from "../store/index.js";

const emailSignIn = (email, password) => {
	console.log(email, password);
	return new Promise((resolve, reject) => {
		auth
			.signInWithEmailAndPassword(email, password)
			.then((res) => {
				console.log("register success");
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const resetPassword = (email) => {
	return new Promise((resolve, reject) => {
		auth
			.sendPasswordResetEmail(email)
			.then((res) => resolve(res))
			.catch((e) => reject(e));
	});
};

const getCurrentAuthUser = () => {
	return auth.currentUser;
};

const signOut = () => {
	return new Promise((resolve, reject) => {
		auth
			.signOut()
			.then((res) => resolve(res))
			.catch((e) => reject(e));
	});
};

const getIdToken = () => {
	return new Promise((resolve, reject) => {
		auth.currentUser
			.getIdToken(true)
			.then((token) => resolve(token))
			.catch((e) => reject(e));
	});
};

const getIdTokenResult = () => {
	return new Promise((resolve, reject) => {
		auth.currentUser
			.getIdTokenResult()
			.then((result) => {
				resolve(result);
			})
			.catch((e) => reject(e));
	});
};

const authStateObserver = () => {
	return new Promise((resolve, reject) => {
		auth.onAuthStateChanged(
			(user) => {
				if (user) {
					resolve(user);
				} else {
					resolve(null);
				}
			},
			(err) => {
				reject(err);
			}
		);
	});
};

export {
	getCurrentAuthUser,
	emailSignIn,
	resetPassword,
	signOut,
	getIdToken,
	authStateObserver,
	getIdTokenResult,
};

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const defaultState = () => {
	return {
		foo: null,
	};
};

export const store = createStore({
	state: defaultState(),
	plugins: [createPersistedState()],
	mutations: {
		setCycleStartRace(state, val) {
			state.foo = val;
		},
	},
});

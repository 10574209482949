import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { IonicVue } from "@ionic/vue";

import { store } from "./store/index.js";

/*
import Amplify from 'aws-amplify';
import aws_config from './aws-config'
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

if (process.env.NODE_ENV !== 'production' &&
  process.env.NODE_ENV !== 'test' &&
  typeof console !== 'undefined'
) {
  aws_config.Auth.oauth.redirectSignIn = "http://localhost:8100/signin";
  aws_config.Auth.oauth.redirectSignOut = "http://localhost:8100/signout";
} else {
  aws_config.Auth.oauth.redirectSignIn = "myapp://localhost:8100/signin";
  aws_config.Auth.oauth.redirectSignOut = "myapp://localhost:8100/signout";
}

*/
//console.log(aws_config)

/*Amplify.configure(aws_config);
applyPolyfills().then(() => {
  defineCustomElements(window);
});*/

//firebase.init();

//import { defineCustomElements } from '@ionic/pwa-elements/loader';

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/core.css";

//Global components
import BaseLayout from "./components/base/BaseLayout.vue";

import "./registerServiceWorker";

import VueObserveVisibility from "vue-observe-visibility";

const app = createApp(App);
app.use(IonicVue);
app.use(router);
app.use(store);
app.use(VueObserveVisibility);

app.component("base-layout", BaseLayout);

router.isReady().then(() => {
	app.mount("#app");
});
